@font-face {
    font-family: 'neucha-regular';
    src: url('/assets/fonts/Neucha-Regular.ttf');
    src: url('/assets/fonts/Neucha-Regular.ttf') format('embedded-opentype'),
        url('/assets/fonts/Neucha-Regular.ttf') format('truetype'),
}

html {
    height: 100vh;
    font-family: "neucha-regular";
    line-height: 2.2em;
}

body {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "main" "footer";
    grid-template-rows: 176px 1fr 176px;
    margin: 0;
}

a:visited {
    color: black;
}

header {
    grid-area: header;
    display: grid;
    z-index: 100;
}

main {
    grid-area: main;
}

.content-image {
    max-width: 100%;
    margin: 20px 0px;
}

figcaption {
    font-size: medium;
}

hr {
    border: 0;
    clear:both;
    display:block;
    width: 100%;               
    background-color: gainsboro;
    height: 1px;
}

#arrow {
    border-right:2px solid black;
    border-bottom:2px solid black;
    width:10px;
    height:10px;
    transform: rotate(-45deg);
    margin-right:10px;
    display: inline-block;
}

/* Desktop */
@media screen and (min-width: 600px) {

    header {
        grid-template-columns: 230px auto;
    }

    header .logo {
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 20px;
    }

    header .logo img {
        content: url('../images/logo.png');
    }

    header .toggler label {
        display: none;
    }

    nav {
        display: flex;
        justify-content:flex-end;
        align-items: center;
        padding-right: 100px;
        font-size: 25px;
        font-weight: bold;
    }

    nav a {
        color: black;
        text-decoration: none;
        margin: 0px 0px 10px 20px;
    }
    
    nav a:hover {
        text-decoration: underline;
    }

    nav a.underlined {
        text-decoration: underline;
    }

    main article {
        background-color:#fff;
        width: 70%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
/* Mobile */
@media screen and (max-width: 600px) {
    
    body {
        grid-template-rows: 100px 1fr 70px;
    }
    
    header {
        display: grid;
        grid-template-rows: 100px 1fr;
    }

    header .container {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    header .container .logo {
        display: flex;
        justify-content: left;
        align-items: center;
    }

    header .container .logo img {
        content: url('../images/logo.png');
    }

    header .toggler {
        justify-self: end;
        padding-right: 25px;
    }

    nav {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        padding: 85px 52px;
        gap: 25px;
        font-size: 40px;
    }

    main {
        grid-area: main;
    }

    main article {
        background-color:#fff;
        width: 100%;
        box-shadow: none;
    }

}

/* Main */

main {
    /* background-image: url("../images/bg.png");
    background-repeat: repeat; */
    display: flex;
    justify-content: center;
    z-index: 100;
}

main article header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 176px;
    font-size: 3em;
}

main article section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8em;
    padding: 0 50px 50px 50px;
}

main article section span.underlined {
    text-decoration: underline;
}

main article section a {
    color: black;
}

main article section a {
    margin-top: 26px;
}

main article section .description {
    margin-top: 100px;
}

/* Footer */

footer {
    grid-area: footer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

footer li {
    float: left;
    margin: 0px 0px 10px 20px;
}

footer a {
    color: black;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

footer li.copyright {
    transform: rotate(-4deg);
    text-decoration: underline;
    font-size: xx-small;
}

/* TOGGLER */

.checkbox {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}

.hamburger {
    margin: 0 auto;
    margin-top: 30px;
    width: 30px;
    height: 30px;
    position: relative;
}

.hamburger .bar {
    padding: 0;
    width: 30px;
    height: 4px;
    background-color: black;
    display: block;
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
    position: absolute; 
}

.bar1 {
    top: 0;
}

.bar2, .bar3 {
    top: 13.5px;
}

.bar3 {
    right: 0;
}

.bar4 {
    bottom: 0;
}

/* HAMBURGER */

.hamburger .bar1 {
    transform-origin: 5%;
}

.hamburger .bar4 {
    transform-origin: 5%;
}

.checkbox:checked ~ main {
    display: none;
}

.checkbox:checked ~ #main {
    display: none;
}

.checkbox:checked ~ #wrapper {
    display: none;
}

.checkbox:checked + header nav {
    display: flex;
}

.checkbox:checked + header label > .hamburger > .bar1{
    transform: rotate(45deg);
    height: 3px;
    width: 42px;
}

.checkbox:checked + header label > .hamburger > .bar3{
    transform: rotate(45deg);
    height: 3px;
    background-color: transparent;
}

.checkbox:checked + header label > .hamburger > .bar2{
    transform: rotate(-45deg);
    height: 3px;
    background-color: transparent;
}

.checkbox:checked + header label > .hamburger > .bar4{
    transform: rotate(-45deg);
    height: 3px;
    width: 42px;
}