@font-face {
  font-family: neucha-regular;
  src: url("Neucha-Regular.c27699e3.ttf");
  src: url("Neucha-Regular.c27699e3.ttf") format("embedded-opentype"), url("Neucha-Regular.c27699e3.ttf") format("truetype"), ;
}

html {
  height: 100vh;
  font-family: neucha-regular;
  line-height: 2.2em;
}

body {
  min-height: 100vh;
  grid-template: "header" 176px
                 "main" 1fr
                 "footer" 176px
                 / 1fr;
  margin: 0;
  display: grid;
}

a:visited {
  color: #000;
}

header {
  z-index: 100;
  grid-area: header;
  display: grid;
}

main {
  grid-area: main;
}

.content-image {
  max-width: 100%;
  margin: 20px 0;
}

figcaption {
  font-size: medium;
}

hr {
  clear: both;
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  border: 0;
  display: block;
}

#arrow {
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  margin-right: 10px;
  display: inline-block;
  transform: rotate(-45deg);
}

@media screen and (min-width: 600px) {
  header {
    grid-template-columns: 230px auto;
  }

  header .logo {
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    display: flex;
  }

  header .logo img {
    content: url("logo.abc5d619.png");
  }

  header .toggler label {
    display: none;
  }

  nav {
    justify-content: flex-end;
    align-items: center;
    padding-right: 100px;
    font-size: 25px;
    font-weight: bold;
    display: flex;
  }

  nav a {
    color: #000;
    margin: 0 0 10px 20px;
    text-decoration: none;
  }

  nav a:hover, nav a.underlined {
    text-decoration: underline;
  }

  main article {
    width: 70%;
    background-color: #fff;
    box-shadow: 0 4px 4px #00000040;
  }
}

@media screen and (max-width: 600px) {
  body {
    grid-template-rows: 100px 1fr 70px;
  }

  header {
    grid-template-rows: 100px 1fr;
    display: grid;
  }

  header .container {
    grid-template-columns: 50% 50%;
    display: grid;
  }

  header .container .logo {
    justify-content: left;
    align-items: center;
    display: flex;
  }

  header .container .logo img {
    content: url("logo.abc5d619.png");
  }

  header .toggler {
    justify-self: end;
    padding-right: 25px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 85px 52px;
    font-size: 40px;
    display: none;
  }

  main {
    grid-area: main;
  }

  main article {
    width: 100%;
    box-shadow: none;
    background-color: #fff;
  }
}

main {
  z-index: 100;
  justify-content: center;
  display: flex;
}

main article header {
  height: 176px;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  display: flex;
}

main article section {
  flex-direction: column;
  justify-content: center;
  padding: 0 50px 50px;
  font-size: 1.8em;
  display: flex;
}

main article section span.underlined {
  text-decoration: underline;
}

main article section a {
  color: #000;
  margin-top: 26px;
}

main article section .description {
  margin-top: 100px;
}

footer {
  z-index: 100;
  flex-direction: row;
  grid-area: footer;
  justify-content: center;
  align-items: center;
  display: flex;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

footer li {
  float: left;
  margin: 0 0 10px 20px;
}

footer a {
  color: #000;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer li.copyright {
  font-size: xx-small;
  text-decoration: underline;
  transform: rotate(-4deg);
}

.checkbox {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.hamburger {
  width: 30px;
  height: 30px;
  margin: 30px auto 0;
  position: relative;
}

.hamburger .bar {
  width: 30px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  padding: 0;
  transition: all .4s ease-in-out;
  display: block;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2, .bar3 {
  top: 13.5px;
}

.bar3 {
  right: 0;
}

.bar4 {
  bottom: 0;
}

.hamburger .bar1, .hamburger .bar4 {
  transform-origin: 5%;
}

.checkbox:checked ~ main, .checkbox:checked ~ #main, .checkbox:checked ~ #wrapper {
  display: none;
}

.checkbox:checked + header nav {
  display: flex;
}

.checkbox:checked + header label > .hamburger > .bar1 {
  height: 3px;
  width: 42px;
  transform: rotate(45deg);
}

.checkbox:checked + header label > .hamburger > .bar3 {
  height: 3px;
  background-color: #0000;
  transform: rotate(45deg);
}

.checkbox:checked + header label > .hamburger > .bar2 {
  height: 3px;
  background-color: #0000;
  transform: rotate(-45deg);
}

.checkbox:checked + header label > .hamburger > .bar4 {
  height: 3px;
  width: 42px;
  transform: rotate(-45deg);
}

/*# sourceMappingURL=index.e7351dc3.css.map */
